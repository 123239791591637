/**
 * Dodaje lub usuwa klasę tagu <html>
 * @type {module}
 */
app.service('html',function(console, cookie){
	var element=$('html');

	function addClass(name){
		if(name && element.length && !element.hasClass(name)){
			element.addClass(name);
			console.log('HTML add: '+name);
		}
	}
	function removeClass(name){
		if(name && element.length && element.hasClass(name)){
			element.removeClass(name);
			console.log('HTML remove: '+name);
		}
	}
	function toggleClass(name){
		if(name && element.length) {
			element.toggleClass(name);
			console.log('HTML toggle: '+name);
		}
	}
	function add(addClassName, removeClassName){
		addClass(addClassName);
		removeClass(removeClassName);
	}
	function remove(removeClassName,addClassName){
		removeClass(removeClassName);
		addClass(addClassName);
	}
	function has(className){
		return (className && element.length && element.hasClass(className));
	}

	if(/Edge\/|Trident\/|MSIE /.test(window.navigator.userAgent)){
		add('ie-mode');
	}

	if (cookie.get('navTestAB') == 'B') {
		add('new-menu');
	} else {
		add('old-menu');
	}

	return {
		add:add,
		remove:remove,
		has:has,
		toggle: toggleClass
	};
});
