/**
 * moduł trzymający informacje o validacji
 *@module validate
 */
app.service('validate', function (front, passwordInvalid) {
	var rules = {
		email: {
			required: true,
			email: true,
			maxlength: 200
		},
		strongPassword: {
			passwordStrong: true
		},
		email2: {
			email: true
		},
		emailRepeat: {
			required: true,
			email: true,
			equalTo: "#email"
		},
		recaptcha: {
			required: true
		},
		passwordOld: {
			required: true
		},
		login: {
			required: true
		},
		password: {
			required: true,
			minlength: 5
		},
		passwordRepeat: {
			required: true,
			equalTo: "#password"
		},
		firstname: {
			required: true,
			minlength: 2,
			firstname: true
		},
		surname: {
			required: true,
			minlength: 2,
			surname: true
		},
		userType: {
			required: true
		},
		zipCode: {
			required: true,
			zipCode: true
		},
		phone: {
			required: true,
			phone: true
		},
		city: {
			required: true,
			minlength: 2,
			maxlength: 40,
			city: true
		},
		street: {
			required: true,
			minlength: 2,
			maxlength: 60,
			street: true
		},
		nip: {
			required: true,
			nip: true
		},
		companyName: {
			required: true,
			maxlength: 160,
			companyName: true
		},
		houseNumber: {
			required: true,
			maxlength: 10,
			houseNumber: true
		},
		flat: {
			maxlength: 10,
			flat: true
		},
		termsAccepted: {
			required: true
		},
		pesel: {
			required: true,
			pesel: true
		},
		warrantyRegulations: {
			required: true
		},
		shopCode: {
			required: true,
			minlength: 2
		},
		cityCode: {
			required: true,
			min: 1
		},
		parcelMachineCode: {
			required: true
		},
		postOfficeCode: {
			required: true
		},
		pofficeZipCode: {
			zipCode: true
		},
		postCityCode: {
			required: false
		},
		messageTextarea: {
			required: true
		},
		parcelMachineCity: {
			required: true
		},
		birthday: {
			required: true,
			birthday: true
		},
		notice: {
			required: true,
			email: true
		},
		nick: {
			required: true,
			maxlength: 200
		},
		shortOpinion: {
			required: true,
			maxlength: 200
		},
		opinion: {
			required: true,
			minlength: 20,
			maxlength: 5000
		},
		opinionStars: {
			required: true
		},
		instalmentShopCode: {
			required: true,
			minlength: 2
		},
		instalmentCityCode: {
			required: true,
			min: 1
		},
		ePaymentBank: {
			required: true,
			minlength: 1
		},
		ePaymentBankBluemedia: {
			required: true,
			minlength: 1
		},
		instalmentCheckbox: {
			required: true
		},
		giftCardValue: {
			required: true,
			min: 10,
			max: 10000
		},
		giftCardStyle:{
			required: true
		},
		agreement: {
			required: true
		},
		optionalPhone: {
			phone: true
		},
		captcha: {
			required: true
		}
	};
	var messages = {
		firstname: {
			required: "Podaj imię",
			minlength: "Imię jest za krótkie"
		},
		surname: {
			required: "Podaj nazwisko",
			minlength: "Nazwisko jest za krótkie"
		},
		userType: {
			required: "Wybierz sposób zakupów"
		},
		zipCode: {
			required: "Podaj kod pocztowy"
		},
		city: {
			required: "Podaj miejscowość",
			minlength: "Miasto jest za krótkie",
			maxlength: "Pole może zawierać maksymalnie 40 znaków"
		},
		phone: {
			required: "Podaj numer telefonu",
			phone: "Podaj poprawny numer telefonu (9 cyfr)"
		},
		street: {
			required: "Podaj ulicę",
			minlength: "Ulica jest za krótka",
			maxlength: "Pole może zawierać maksymalnie 60 znaków"
		},
		companyName: {
			required: "Podaj nazwę firmy",
			maxlength: "Pole może zawierać maksymalnie 160 znaków"
		},
		nip: {
			required: "Podaj poprawny NIP"
		},
		houseNumber: {
			required: "Podaj numer domu",
			maxlength: "Pole może zawierać maksymalnie 10 znaków"
		},
		flat: {
			maxlength: "Pole może zawierać maksymalnie 10 znaków"
		},
		login: {
			required: "Podaj login"
		},
		passwordOld: {
			required: "Podaj swoje hasło"
		},
		passwordNew: {
			required: "Podaj nowe hasło",
			minlength: "Podaj co najmniej 5 znaków"
		},
		passwordRepeat: {
			required: "Powtórz nowe hasło",
			equalTo: "Hasła muszą być identyczne"
		},
		email: {
			required: "Wpisz adres e-mail",
			email: "Wpisz poprawny adres e-mail",
			maxlength: "Podany adres e-mail jest za długi"
		},
		email2: {
			email: "Podaj poprawnego e-maila albo pozostaw pole puste"
		},
		emailRepeat: {
			required: "Powtórz adres e-mail",
			email: "Powtórz poprawny adres e-mail",
			equalTo: "Podane adresy nie są identyczne"
		},
		recaptcha: {
			required: "Prosimy zaznacz nie jestem robotem"
		},
		termsAccepted: {
			required: "Akceptacja Regulaminu jest wymagana"
		},
		noReturnNoticeAccepted: {
			required: "Akceptacja jest wymagana"
		},
		pesel: {
			required: "Podaj numer PESEL"
		},
		warrantyRegulations: {
			required: "Zapoznaj sie z warunkami ubezpieczenia"
		},
		shopCode: {
			required: front.euro ? "Wybierz sklep, w którym chcesz odebrać Towary" : "Wybierz punkt odbioru, w którym chcesz odebrać Towary",
			minlength: front.euro ? "Wybierz sklep, w którym chcesz odebrać Towary" : "Wybierz punkt odbioru, w którym chcesz odebrać Towary"
		},
		cityCode: {
			required: "Wybierz miejscowość, w której chcesz odebrać Towary",
			min: "Wybierz miejscowość, w której chcesz odebrać Towary"
		},
		parcelMachineCode: {
			required: "Wybierz Paczkomat®, w którym chcesz odebrać Towary"
		},
		postOfficeCode: {
			required: "Proszę wybrać punkt odbioru, w którym chcesz odebrać produkt"
		},
		// postCityCode:{
		// 	required: 'Wybierz placówkę pocztową, w której chcesz odebrać produkty'
		// },
		messageTextarea: {
			required: "Wiadomość nie może być pusta"
		},
		parcelMachineCity: {
			required: "Wybierz miejscowość, w której chcesz odebrać Towary"
		},
		ePaymentBank: {
			required: "Wybierz bank"
		},
		notice: {
			required: "Wpisz adres e-mail",
			email: "Wpisz poprawny adres e-mail"
		},
		birthday: {
			required: "Podaj datę urodzin"
		},
		nick: {
			required: "Podaj nick",
			maxlength: "Podany nick jest za długi"
		},
		shortOpinion: {
			required: "Podaj tytuł Opinii",
			maxlength: "Podany tytuł jest za długi"
		},
		opinion: {
			required: "Podaj treść Opinii",
			minlength: "Podana treść jest za krótka",
			maxlength: "Podana treść jest za długa"
		},
		instalmentShopCode: {
			required: "Wybierz sklep, w którym chcesz odebrać Towary",
			minlength: "Wybierz sklep, w którym chcesz odebrać Towary"
		},
		instalmentCityCode: {
			required: "Wybierz miejscowość, w której chcesz odebrać Towary",
			min: "Wybierz miejscowość, w której chcesz odebrać Towary"
		},
		ePaymentBankBluemedia: {
			required: "Wybierz bank, w którym posiadasz internetowe konto osobiste"
		},
		instalmentCheckbox: {
			required: "Ten warunek jest konieczny aby przejść do kolejnego etapu zamówienia"
		},
		giftCardValue: {
			required: "Wybierz wartość Karty Podarunkowej",
			min: "Minimalna wartość karty to 10zł",
			max: "Maksymalna wartość karty to 10000zł"
		},
		giftCardStyle:{
			required: "Wybierz wzór Karty Podarunkowej"
		},
		agreement: {
			required: "Zgoda jest wymagana"
		},
		optionalPhone: {
			phone: "Podaj poprawny numer telefonu (9 cyfr)"
		},
		captcha: {
			required: "Przepisz tekst z obrazka"
		}
	};
	var defaults = {
		ignore: ".ignore",
		errorElement: "label",
		success: function(label) {
			var $validateTarget = label.closest('.validate-target');
			if ($validateTarget.length) {
				$validateTarget.addClass('validate-ok').removeClass('validate-error');
			}
			if (label.parent().hasClass('validate-error')) {
				label.parent().addClass('validate-ok').removeClass('validate-error');
			}
			label.remove();
		},
		invalidHandler: function(form, validator) {
			if (!$(form.target).closest('.remodal-wrapper').length) { //jeśli bład występuje na warstwie to nie przesuwamy
				var $elementWithError = $(validator.errorList[0].element);
				var $elementToScroll = false;
				if ($elementWithError.closest('.validate-target').length) {
					$elementToScroll = $elementWithError.closest('.validate-target');
				} else {
					$elementToScroll = $elementWithError.parent();
				}
				if($elementToScroll && $elementToScroll.length){
					let topHeight = $elementToScroll.offset().top + $elementToScroll.height();
					let bottomHeight = $elementToScroll.offset().top;
					//console.log(topHeight , "<", window.scrollY, ' || ', bottomHeight, ' > ', window.scrollY + window.innerHeight)
					if(topHeight < window.scrollY || bottomHeight > window.scrollY + window.innerHeight){
						$.scrollTo($elementToScroll);
					}
				}
			}
		},
		errorPlacement: function(error, element) {
			var $validateTarget = element.closest('.validate-target');
			if (element.attr('type') == 'radio' && element.closest('.radio-group').length) {
				error.appendTo(element.closest('.radio-group'));
			} else if (element.attr('type') == 'checkbox') {
				element.parent().addClass('validate-error').append(error);
				/* Najbliższemu elementowi o klasie validate-target także nadaj error */
				$validateTarget.addClass('validate-error').removeClass('validate-ok');
			} else if ($validateTarget.length) {
				$validateTarget.addClass('validate-error').removeClass('validate-ok');
				$validateTarget.append(error);
			} else {
				error.insertAfter(element);
			}
		}
	};

	function setOptions(options) {
		var obj = {};
		for (var i in defaults) {
			obj[i] = defaults[i];
		}
		return $.extend(true, obj, options);
	}
	var tasks = {
		warrantyUpsellingForm({ $form, submitHandler }) {
			const options = setOptions({
				submitHandler: submitHandler,
				rules: {
					"acceptance-1": {
						required: true
					},
					"acceptance-2": {
						required: true
					},
					"pesel": rules.pesel
				},
				messages: {
					"acceptance-1": {
						required: "Zapoznaj sie z warunkami ubezpieczenia"
					},
					"acceptance-2": {
						required: "Zapoznaj się z Regulaminem płatności cyklicznej"
					},
					"pesel": messages.pesel
				}
			});
			$form.validate(options);
		},
		subscriptionServiceInvoiceDataForm: function(props) {
			const { $form, onValidInvoiceData } = props;
			const options = setOptions({
				submitHandler: onValidInvoiceData,
				rules: {
					"firstName": rules.firstname,
					"lastName": rules.surname,
					"zipCode": rules.zipCode,
					"city": rules.city,
					"street": rules.street,
					"flatNumber": rules.flat,
					"houseNumber": rules.houseNumber,
					"nip": rules.nip,
					"companyName": rules.companyName
				},
				messages: {
					"firstName": messages.firstname,
					"lastName": messages.surname,
					"zipCode": messages.zipCode,
					"city": messages.city,
					"street": messages.street,
					"flatNumber": messages.flat,
					"houseNumber": messages.houseNumber,
					"nip": messages.nip,
					"companyName": messages.companyName
				}
			});
			$form.validate(options);
		},
		errorForm: function(sendFunction) {
			var form = $('#error-form');
			var options = setOptions({
				submitHandler: function(form) {
					sendFunction($(form));
				},
				rules: {
					"description": {
						required: true
					},
					"email": {
						email: true,
						required: () => $("#error-subject").val() != 'CONTENT_VIOLATION_ERROR'
					},
					"legalStatementSelected": {
						required: true
					},
					"g-recaptcha-response": rules.recaptcha
				},
				messages: {
					"description": {
						required: "Wpisz treść wiadomości"
					},
					"email": {
						email: "Podaj poprawny adres e-mail",
						required: "Wpisz adres e-mail"
					},
					"legalStatementSelected": {
						required: "Akceptacja oświadczenia jest wymagana"
					},
					"g-recaptcha-response": messages.recaptcha
				}
			});
			form.validate(options);
		},
		articleVoteUpForm: function(sendFunction) {
			var form = $('#article-vote-up-form');
			var options = setOptions({
				submitHandler: function(form) {
					sendFunction($(form));
				},
				rules: {
					"content": {
						required: true
					},
					"email": {
						required: function(){
							return $('#vote-up-email-agreement', form).is(':checked');
						},
						email: true
					},
					"g-recaptcha-response": rules.recaptcha
				},
				messages: {
					"content": {
						required: "Wpisz treść wiadomości"
					},
					"email": {
						required: "Wpisz adres e-mail",
						email: "Wpisz poprawny adres e-mail"
					},
					"g-recaptcha-response": messages.recaptcha
				}
			});
			form.validate(options);
		},
		articleVoteDownForm: function(sendFunction) {
			var form = $('#article-vote-down-form');
			var options = setOptions({
				submitHandler: function(form) {
					sendFunction($(form));
				},
				rules: {
					"reason": {
						required: true
					},
					"email": {
						required: function(){
							return $('#vote-down-email-agreement', form).is(':checked');
						},
						email: true
					},
					"g-recaptcha-response": rules.recaptcha
				},
				messages: {
					"reason": {
						required: "Wybierz jedną z opcji",
					},
					"email": {
						required: "Wpisz adres e-mail",
						email: "Wpisz poprawny adres e-mail"
					},
					"g-recaptcha-response": messages.recaptcha
				}
			});
			form.validate(options);
		},
		articleAdviceForm: function(sendFunction) {
			var form = $('#article-advice-form');
			var options = setOptions({
				submitHandler: function(form) {
					sendFunction($(form));
				},
				rules: {
					"topic": {
						required: true
					},
					"content": {
						required: true
					},
					"email": {
						email: true
					},
					"g-recaptcha-response": rules.recaptcha
				},
				messages: {
					"topic": {
						required: "Wpisz temat"
					},
					"content": {
						required: "Wpisz treść wiadomości"
					},
					"g-recaptcha-response": messages.recaptcha
				}
			});
			form.validate(options);
		},
		technicalError: function(sendFunction) {
			var form = $('#technical-form');
			var options = setOptions({
				submitHandler: function(form) {
					sendFunction($(form));
				},
				rules: {
					"description": {
						required: true
					}
				},
				messages: {
					"description": {
						required: "Wpisz treść wiadomości"
					}
				}
			});
			form.validate(options);
		},
		opinion: function(settings) {
			var form = $("#add-opinion-form form");
			var options = setOptions({
				submitHandler: function(form) {
					settings.submit(form);
				},
				rules: {
					"shortOpinion": rules.shortOpinion,
					"opinion": rules.opinion,
					"nick": rules.nick,
					"email": rules.email,
					"opinionStars": {
						min: settings.starLength
					},
					"opinionTermsAccepted": rules.agreement
				},
				messages: {
					"shortOpinion": messages.shortOpinion,
					"opinion": messages.opinion,
					"nick": messages.nick,
					"email": messages.email,
					"opinionStars": {
						min: "Zaznacz ocenę Towaru"
					},
					"opinionTermsAccepted": messages.agreement
				}
			});
			form.validate(options);
		},
		opinionQuestion: function(sendFunction) {
			var form = $('#question-form' + sendFunction.id);
			var options = setOptions({
				submitHandler: function(form) {
					sendFunction.submit(form);
				},
				rules: {
					"content": rules.opinion,
					"nick": rules.nick,
					"email": rules.email,
				},
				messages: {
					"content": messages.opinion,
					"nick": messages.nick,
					"email": messages.email,
				}
			});
			form.validate(options);
		},
		opinionAnswer: function(sendFunction) {
			var form = $('#answer-mode-form' + sendFunction.id + ' form');
			var options = setOptions({
				submitHandler: function(form) {
					sendFunction.submit(form);
				},
				rules: {
					"content": rules.opinion,
					"nick": rules.nick,
					"email": rules.email,
				},
				messages: {
					"content": messages.opinion,
					"nick": messages.nick,
					"email": messages.email,
				}
			});
			form.validate(options);
		},
		warrantyEncouragement: function(sendFunction) {
			var $form = $('#warranty-form');
			var options = setOptions({
				submitHandler: function(form) {
					sendFunction();
				},
				errorLabelContainer: $form.find('.js-warranty-error'),
				rules: {
					"warranty_": {
						required: true
					}
				},
				messages: {
					"warranty_": {
						required: "Wybierz jedną z opcji ochrony"
					}
				}
			});
			$form.validate(options);
		},
		notIssuedOrder: function() {
			var $form = $('#insurance-form');
			var options = setOptions({
				rules: {
					"firstName": rules.firstname,
					"lastName": rules.surname,
					"email": rules.email2,
					"pesel": rules.pesel,
					"zipCode": rules.zipCode,
					"city": rules.city,
					"companyName": rules.companyName,
					"nip": rules.nip,
					"zipCode": rules.zipCode,
					"street": rules.street,
					"houseNumber": rules.houseNumber,
					"flatNumber": rules.flat
				},
				messages: {
					"firstName": messages.firstname,
					"lastName": messages.surname,
					"email": messages.email2,
					"pesel": messages.pesel,
					"zipCode": messages.zipCode,
					"city": messages.city,
					"companyName": messages.companyName,
					"nip": messages.nip,
					"zipCode": messages.zipCode,
					"street": messages.street,
					"houseNumber": messages.houseNumber,
					"flatNumber": messages.flat
				}
			});
			$form.validate(options);
		},
		wcLogin: function() {
			var $form = $('#wc-login-form');
			var options = setOptions({
				rules: {
					mgLogin: {
						required: true
					},
					mgPassword: {
						required: true
					}
				},
				messages: {
					mgLogin: {
						required: "Podaj login"
					},
					mgPassword: {
						required: "Podaj hasło"
					}
				}
			});
			$form.validate(options);
		},
		notice: function(sendFunction) {
			var form = $("#notice-form");
			var options = setOptions({
				submitHandler: function() {
					sendFunction(form);
				},
				rules: {
					notice: rules.notice,
					"g-recaptcha-response": rules.recaptcha
				},
				messages: {
					notice: messages.notice,
					"g-recaptcha-response": messages.recaptcha
				}
			});
			form.validate(options);
		},
		question: function(sendFunction) {
			var form = $("#question-form");
			var options = setOptions({
				submitHandler: function() {
					sendFunction();
				},
				rules: {
					content: {
						required: true,
						minlength: form.data("min-char") / 1,
						maxlength: form.data("max-char") / 1
					},
					questionReceiver: {
						required: true
					}
				},
				messages: {
					content: {
						required: "Podaj treść pytania",
						minlength: form.data("min-text"),
						maxlength: form.data("max-text")
					},
					questionReceiver: {
						required: "Wybierz typ pytania"
					}
				}
			});
			form.validate(options);
		},
		answer: function(params) {
			var form = params.form;
			var options = setOptions({
				submitHandler: function() {
					params.success();
				},
				rules: {
					content: {
						required: true,
						minlength: form.data("min-char") / 1,
						maxlength: form.data("max-char") / 1
					}
				},
				messages: {
					content: {
						required: "Podaj treść odpowiedzi",
						minlength: form.data("min-text"),
						maxlength: form.data("max-text")
					}
				}
			});
			form.validate(options);
		},
		comment: function(params) {
			var form = params.form;
			var options = setOptions({
				submitHandler: function() {
					params.success();
				},
				rules: {
					content: {
						required: true,
						minlength: form.data("min-char") / 1,
						maxlength: form.data("max-char") / 1
					}
				},
				messages: {
					content: {
						required: "Podaj treść komentarza",
						minlength: form.data("min-text"),
						maxlength: form.data("max-text")
					}
				}
			});
			form.validate(options);
		},
		wcClientRegistration: function(params) {
			var options = setOptions({
				submitHandler: function(form) {
					params.submit(form);
				},
				rules: {
					"firstname": rules.firstname,
					"surname": rules.surname,
					"email": rules.email,
					"emailRepeat": rules.emailRepeat,
					"phone": rules.optionalPhone,
					"statuteAccepted": rules.termsAccepted
				},
				messages: {
					"firstname": messages.firstname,
					"surname": messages.surname,
					"email": messages.email,
					"emailRepeat": messages.emailRepeat,
					"phone": messages.optionalPhone,
					"statuteAccepted": messages.termsAccepted
				}
			});
			params.form.validate(options);
		},
		wcClientEditData: function(sendFunction) {
			var options = setOptions({
				submitHandler: function(form) {
					sendFunction(form);
				},
				rules: {
					"phone": rules.optionalPhone
				},
				messages: {
					"phone": messages.optionalPhone
				}
			});
			$('#customer-edit-form').validate(options);
		},
		limitedService: function(sendFunction) {
			var options = setOptions({
				submitHandler: function(form) {
					sendFunction(form);
				},
				rules: {
					"zipCode": rules.zipCode,
					"cityName": rules.city
				},
				messages: {
					"zipCode": messages.zipCode,
					"cityName": messages.city
				}
			});
			$('#limited-service-form').validate(options);
		},
		loginView: function(sendFunction) {
			var options = setOptions({
				submitHandler: function(form) {
					sendFunction();
				},
				rules: {
					"j_username": rules.email,
					"j_password": rules.password,
					"captcha" : rules.captcha,
					"g-recaptcha-response" : rules.recaptcha
				},
				messages: {
					"j_username": messages.email,
					"j_password": messages.password,
					"captcha" : messages.captcha,
					"g-recaptcha-response" : messages.recaptcha
				}
			});
			$("#loginView-login").validate(options);
		},
		registerLayer: function(sendFunction) {
			var options = setOptions({
				submitHandler: function() {
					sendFunction();
				},
				rules: {
					nick: rules.nick,
					firstname: rules.firstname,
					surname: rules.surname,
					email: rules.email,
					password: rules.strongPassword,
					password2: rules.passwordRepeat,
					statuteAccepted: rules.termsAccepted,
					"g-recaptcha-response" : rules.recaptcha
				},
				messages: {
					nick: messages.nick,
					firstname: messages.firstname,
					surname: messages.surname,
					email: messages.email,
					password2: messages.passwordRepeat,
					statuteAccepted: messages.termsAccepted,
					"g-recaptcha-response" : messages.recaptcha
				}
			});
			$("#loginView-register").validate(options);
		},
		obligatoryChangePassword: function(sendFunction) {
			var options = setOptions({
				submitHandler: function() {
					sendFunction()
				},
				rules: {
					"password": rules.strongPassword,
					"currentPassword": rules.passwordOld,
					"confirmedPassword": rules.passwordRepeat
				},
				messages: {
					"currentPassword": messages.passwordOld,
					"confirmedPassword": messages.passwordRepeat
				},
			});
			$("#change-password form").validate(options);
		},
		changePayment: function(params){
			var options=setOptions({
				rules:{
					"advanceSaleDeliveryTermsAccepted": {required: true},
					"paymentOperatorAgreementAccepted": {required: true},
					"termsAccepted": {required: true},	// formularz jest dla "zmiany płatności" ale także dla ścieżki negatywnej PAYy i tam jest inne pole na zgody
					"ePaymentBankId": {required: true},
					"deliveryInterval": {required:true}
				},
				messages:{
					"advanceSaleDeliveryTermsAccepted": "Wybór sposobu płatności wymaga akceptacji terminu realizacji",
					"paymentOperatorAgreementAccepted": params.payUMode ? messages.termsAccepted : "Wybór sposobu płatności wymaga akceptacji regulaminu",
					"termsAccepted": params.payUMode ? messages.termsAccepted : "Wybór sposobu płatności wymaga akceptacji regulaminu",
					"ePaymentBankId": "Wybierz bank",
					"deliveryInterval": "Wybierz termin dostawy"
				},
				submitHandler: function(form) {
					params.send();
				},
			});
			params.$form.validate(options);
		},
		complaintForm: function(sendFunction) {
			var form = $('#complaint-form');
			var options = setOptions({
				submitHandler: function(form) {
					sendFunction($(form));
				},
				rules: {
					"description": {
						required: true
					},
					"subject": {
						required: true
					}
				},
				messages: {
					"description": {
						required: "Wpisz treść wiadomości"
					},
					"subject": {
						required: "Wybierz kategorię"
					}
				}
			});
			form.validate(options);
		},
		restoreSharedCart: function(sendFunction) {
			var form = $('#restore-cart-form');
			var options = setOptions({
				submitHandler: function(form) {
					sendFunction($(form));
				},
				rules: {
					"identifier": {
						required: function() {
							return !form.find("input[name=link]").hasClass('valid');
						}
					},
					"link": {
						required: function() {
							return !form.find("input[name=identifier]").hasClass('valid');
						}
					}
				},
				messages: {
					"identifier": {
						required: "Przynajmniej jedno z pól jest wymagane"
					},
					"link": {
						required: "Przynajmniej jedno z pól jest wymagane"
					}
				}
			});
			form.validate(options);
		},
		notifyForm: function(sendFunction) {
			var form = $('#notify-form');
			var options = setOptions({
				submitHandler: function(form) {
					sendFunction($(form));
				},
				rules: {
					"description": {
						required: true
					},
					"email": rules.email,
					"g-recaptcha-response": rules.recaptcha
				},
				messages: {
					"description": {
						required: "Wpisz treść wiadomości"
					},
					"email": messages.email,
					"g-recaptcha-response": messages.recaptcha
				}
			});
			form.validate(options);
		},
		availabilityNotificationForm: function(params) {
			const form = params.form;
			const options = setOptions({
				submitHandler: function(form) {
					params.callback(form);
				},
				rules: {
					"email": rules.email,
					"g-recaptcha-response": rules.recaptcha
				},
				messages: {
					"email": messages.email,
					"g-recaptcha-response": messages.recaptcha
				}
			});
			form.validate(options);
		},
		emptySearchResultsContactForm(callback) {
			let $form = $('#contact-form');
			const options = setOptions({
				submitHandler() {
					callback($form);
				},
				rules: {
					"contact-form-message": {
						required: true
					},
					"g-recaptcha-response": rules.recaptcha
				},
				messages: {
					"g-recaptcha-response": messages.recaptcha
				}
			});
			$form.validate(options);
		},
		utteranceForm: function(sendFunction) {
			var form = $('#utterance-form');
			var options = setOptions({
				submitHandler: function(form) {
					sendFunction($(form));
				},
				rules: {
					"client_description": {
						required: true
					},
					"client_email": {
						email: true
					},
					"g-recaptcha-response": rules.recaptcha
				},
				messages: {
					"client_description": {
						required: "Wpisz treść wiadomości"
					},
					"g-recaptcha-response": messages.recaptcha
				}
			});
			form.validate(options);
		},
		rodoForm: function(sendFunction) {
			var form = $('#rodo');
			var options = setOptions({
				submitHandler: function(form) {
					sendFunction($(form));
				},
				rules: {
					firstName: rules.firstname,
					surname: rules.surname,
					newFirstName: rules.firstname,
					newSurname: rules.surname,
					pesel: {
						pesel: true
					},
					email: {
						email: true
					},
					newEmail: {
						email: true
					},
					phone: {
						phone: true
					},
					newPhone: {
						phone: true
					},
					salesmanNumber: {
						required: true
					},
					zipCode: {
						zipCode: true
					},
					newZipCode: {
						zipCode: true
					},
					city: {
						maxlength: 40,
						city: true
					},
					newCity: {
						maxlength: 40,
						city: true
					},
					street: {
						maxlength: 60,
						street: true
					},
					houseNumber: {
						maxlength: 10,
						houseNumber: true
					},
					newStreet: {
						maxlength: 60,
						street: true
					},
					newHouseNumber: {
						maxlength: 10,
						houseNumber: true
					}
				},
				messages: {
					firstName: messages.firstname,
					surname: messages.surname,
					newFirstName: messages.firstname,
					newSurname: messages.surname,
					salesmanNumber: {
						required: 'Numer sprzedawcy jest wymagany'
					}
				}
			});
			form.validate(options);
			if (form.find('label.error').length) {
				$.scrollTo(form.find('label.error:first').offset().top - 200, 0);
			}
		},
		friendForm: function(sendFunction) {
			var form = $('#friend-form');
			var options = setOptions({
				submitHandler: function(form) {
					sendFunction($(form));
				},
				rules: {
					"cms_input_friend_email": rules.email,
					"cms_input_email": rules.email,
					"g-recaptcha-response": rules.recaptcha
				},
				messages: {
					"cms_input_friend_email": messages.email,
					"cms_input_email": messages.email,
					"g-recaptcha-response": messages.recaptcha
				}
			});
			form.validate(options);
		},
		editDefinition: function(settings) {
			var form = $('#dictionary-edit-form');
			var options = setOptions({
				submitHandler: function(form) {
					settings.submit(form);
				},
				rules: {
					content: {
						required: true
					},
					"g-recaptcha-response": rules.recaptcha
				},
				messages: {
					content: {
						required: "Pole jest wymagane"
					},
					"g-recaptcha-response": messages.recaptcha
				}
			});
			form.validate(options);
		},
		pickDeliveryTime: function(settings) {
			var form = $('#delivery-time-form');
			var options = setOptions({
				submitHandler: function(form) {
					settings.submit(form);
				},
				rules: {
					"zipCode": rules.zipCode,
					"cityName": rules.city
				},
				messages: {
					"zipCode": messages.zipCode,
					"cityName": messages.city
				}
			});
			form.validate(options);
		},
		pickDeliveryCity: function(settings) {
			var form = $('#delivery-city-form');
			var options = setOptions({
				submitHandler: function(form) {
					settings.submit(form);
				},
				rules: {
					"zipCode": rules.zipCode,
					"cityName": rules.city
				},
				messages: {
					"zipCode": messages.zipCode,
					"cityName": messages.city
				}
			});
			form.validate(options);
		},
		pickShopDeliveryCity: function(settings) {
			var form = $('#search-city-form');
			var options = setOptions({
				submitHandler: function(form) {
					settings.submit(form);
				},
				rules: {
					"zipCode": rules.zipCode,
					"shopCode": "required"
				},
				messages: {
					"zipCode": messages.zipCode
				}
			});
			form.validate(options);
		}
	};
	var methods = {
		firstname: function(value, element) {
			// pierwsza litera jest dozwoloną literą
			// pozostałe 39 znaki mogą być literą, spacją i myślnikiem
			return /^[a-zA-ZąćęłńóśżźĄĆĘŁŃÓŚŻŹ']{1}[a-zA-ZąćęłńóśżźĄĆĘŁŃÓŚŻŹ\- ']{1,39}$/.test(value) && !/.[\s]{2,}/.test(value) && !/.[-]{2,}/.test(value);
		},
		surname: function(value, element) {
			// pierwsza litera jest dozwoloną literą
			// pozostałe 39 znaki mogą być literą, spacją i myślnikiem
			return /^[a-zA-ZąćęłńóśżźĄĆĘŁŃÓŚŻŹ']{1}[a-zA-ZąćęłńóśżźĄĆĘŁŃÓŚŻŹ\- ']{1,39}$/.test(value) && !/.[\s]{2,}/.test(value) && !/.[-]{2,}/.test(value);
		},
		pesel: function(value, element) {
			var status = true;
			var pesel = value.replace(/[\ \-]/gi, "");
			if (pesel.length != 11) {
				status = false;
			} else {
				var steps = new Array(1, 3, 7, 9, 1, 3, 7, 9, 1, 3);
				var sum_nb = 0;
				var sum_c = 0;
				for (var x = 0; x < 10; x++) {
					sum_nb += steps[x] * pesel.charAt(x);
				}
				var sum_m = 10 - sum_nb % 10;
				if (sum_m != 10) {
					sum_c = sum_m;
				}
				if (sum_c != pesel.charAt(10)) {
					status = false;
				}
			}
			return status;
		},
		phone: function(value, element) {
			var number = value + '';
			if (number.length === 0) {
				return false;
			}
			var numberValue = number.match(/\d/g);
			if (numberValue !== null  && numberValue.length === 9) {
				return true;
			}
			return false;
		},
		street: function(value, element) {
			var testReg1 = new RegExp("^[0-9a-zA-ZąćęłńóśżźĄĆĘŁŃÓŚŻŹ\\- .']{2,60}$");	//dozwolone znaki
			var testReg2 = new RegExp("[a-zA-ZąćęłńóśżźĄĆĘŁŃÓŚŻŹ]+");					//min jedna litera
			return testReg1.test(value) && testReg2.test(value);
		},
		zipCode: function(value, element) {
			var status = true;
			var zipCode = value.replace(/[\ \-]/gi, "");
			if (/\d{5}/.test(zipCode) && zipCode.length >= 5) {
				$(element).val(zipCode.substr(0, 2) + "-" + zipCode.substr(2, 3));
			} else {
				status = false;
			}
			return status;
		},
		city: function(value, element) {
			return /^[a-zA-ZąćęłńóśżźĄĆĘŁŃÓŚŻŹ\- .]{2,40}$/.test(value);
		},
		houseNumber: function(value, element) {
			var max = 10;
			if (value.length > max) {
				return false;
			} else if (/\d/.test(value) && !/;/.test(value) && /^[^\-].*/.test(value)) {  // min 1 cyfra, brak średnika, nie zaczyna się od "-"
				return true;
			}
			return false;
		},
		flat: function(value, element) {
			var max = 10;
			if (value.length > max) {
				return false;
			} else if (!/;/.test(value) && /^[^\-].*/.test(value)) {  // brak średnika, nie zaczyna się od "-"
				return true;
			}
			return false;
		},
		nip: function(value, element) {
			function nipValidator(nip) {
				var verificator_nip = new Array(6, 5, 7, 2, 3, 4, 5, 6, 7);
				var n = 0;
				var ile;
				for (var i = 0; i < 9; i++) {
					ile = nip.charAt(i) / 1 * verificator_nip[i] / 1;
					n += ile;
				}
				// walidacja odrzuca nip z samymi zerami
				if (n === 0) {
					return false;
				}
				n %= 11;
				if (n != nip.charAt(9)) {
					return false;
				} else {
					return true;
				}
			}
			var status = false;
			if (jQuery(element).val() === "") {
				status = true;
			} else {
				var nip = value.replace(/[\ _\-]/gi, "");
				if (/\d{3}-\d{2}-\d{2}-\d{3}/.test(value) || /\d{3}-\d{3}-\d{2}-\d{2}/.test(value) || /\d{10}/.test(value)) {
					status = true;
					if (nip.length != 10) {
						status = false;
					} else {
						status = nipValidator(nip);
						if (!front.katalog && status === true && !/\d{3}-\d{3}-\d{2}-\d{2}/.test(value)) {
							jQuery(element).val(nip.substr(0, 3) + "-" + nip.substr(3, 2) + "-" + nip.substr(5, 2) + "-" + nip.substr(7, 3));
						}
					}
				} else {
					status = false;
				}
			}
			return status;
		},
		passwordStrong: function () {
			return passwordInvalid.check();
		}
	};

	function task(name, params) {
		if (name && tasks[name]) {
			tasks[name](params);
		}
	}
	function resetForm($form){
		$('textarea, input', $form).val('').trigger('change').removeClass('error');
		$('.validate-target', $form).removeClass('validate-error');
		$('label.error', $form).remove();
	}
	var addedMethods = [
		/*['nip','Podaj poprawny nip'],*/
		['phone', 'Podaj poprawny numer telefonu'],
		['zipCode', 'Podaj poprawny kod pocztowy'],
		['pesel', 'Podaj poprawny PESEL'],
		['houseNumber', 'Podaj poprawny numer domu'],
		['flat', 'Podaj poprawny numer mieszkania'],
		['street', 'Podaj poprawną ulicę'],
		['passwordStrong', false, true]
	];
	$(document).ready(function() {
		$(addedMethods).each(function(i, n) {
			var fn = methods[n[0]];
			$.validator.addMethod(n[0], function(value, element, param) {
				if (n[2]) {
					return fn(value, element, param);
				} else {
					return this.optional(element) || fn(value, element, param);
				}
			}, n[1]);
		});
	});
	return {
		task: task,
		resetForm: resetForm
	};
});


/*standardowy mechanizm z modułu validate jest nieczuły na required, jeżeli pole jest puste to reguły np nip nie działają, wymagany byłby required.
Przywrócono dotychczasową starą metodę walidacji nipu.
Przy redesignie ścieżki należy uwspólnić działanie nipu sterując dodawaniem/usuwaniem required jquerowo
*/
function nipValidator(nip) {
	var verificator_nip = new Array(6, 5, 7, 2, 3, 4, 5, 6, 7);
	var n = 0;
	var ile;
	for (var i = 0; i < 9; i++) {
		ile = nip.charAt(i) / 1 * verificator_nip[i] / 1;
		n += ile;
	}
	// walidacja odrzuca nip z samymi zerami
	if (n === 0) {
		return false;
	}
	n %= 11;
	if (n != nip.charAt(9)) {
		return false;
	} else {
		return true;
	}
}


jQuery.validator.addMethod("childBenefit", function(value, element) {
	var $childBenefit = $('#childBenefit');
	var $dependantsNumber = $('#dependantsNumber').length ? $('#dependantsNumber') : $('#numberOfPeopleInTheHousehold');
	var v1= $dependantsNumber.val() || 0;
	var v2 = $childBenefit.val() || 0;
	var child = v1 > 0 ? $dependantsNumber.find('option[value='+v1+']').data('i')/1 : 0;
	var p500 =  v2 > 0 ? $childBenefit.find('option[value='+$childBenefit.val()+']').data('i')/1 : 0;
	if(child != ''){
		if(child * 500 >= p500){
			return true;
		}
	}
	return false;
}, "Wartość świadczenia 500+ jest niezgodna z liczbą dzieci na utrzymaniu.");

jQuery.validator.addMethod("nip", function(value, element) {
	var status = false;
	if (jQuery(element).hasClass('ignore')) {
		status = true;
	} else {
		var nip = value.replace(/[\ _\-]/gi, '');
		if (/\d{3}-\d{2}-\d{2}-\d{3}/.test(value) || /\d{3}-\d{3}-\d{2}-\d{2}/.test(value) || /\d{10}/.test(value)) {
			status = true;
			if (nip.length != 10) {
				status = false;
			} else {
				status = nipValidator(nip);
				if (!jQuery('html.app-katalog').length && status === true && !/\d{3}-\d{3}-\d{2}-\d{2}/.test(value)) {
					jQuery(element).val(nip.substr(0, 3) + '-' + nip.substr(3, 2) + '-' + nip.substr(5, 2) + '-' + nip.substr(7, 3));
				}
			}
		} else if (nip === '' && jQuery(element).hasClass('individual')) {
			status = true;
		} else {
			status = false;
		}
	}
	return status;
}, "Podaj poprawny NIP  w formacie xxx-xx-xx-xxx");
